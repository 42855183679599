import Fetch, { thrownHasStatus } from './index'

export const validConnection = async () => {
    if (typeof process.env.REACT_APP_API_META_FACADE_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_META_FACADE_URL is not defined')
    }

    try {
        return await Fetch.get<{ valid: boolean }>(
            `${process.env.REACT_APP_API_META_FACADE_URL}valid`,
        )
    } catch (err) {
        if (thrownHasStatus(err, 400)) {
            return
        }
        throw err
    }
}

export const initLogin = async () => {
    if (typeof process.env.REACT_APP_API_META_FACADE_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_META_FACADE_URL is not defined')
    }

    return Fetch.get<{ url: string }>(`${process.env.REACT_APP_API_META_FACADE_URL}oauth/init`)
}

export const getAccounts = async () => {
    if (typeof process.env.REACT_APP_API_META_FACADE_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_META_FACADE_URL is not defined')
    }

    try {
        return await Fetch.get<{ id: string; name: string }[]>(
            `${process.env.REACT_APP_API_META_FACADE_URL}accounts`,
        )
    } catch (err) {
        if (thrownHasStatus(err, 400)) {
            return
        }
        throw err
    }
}

export const getSubscribedAccounts = async () => {
    if (typeof process.env.REACT_APP_API_META_FACADE_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_META_FACADE_URL is not defined')
    }

    try {
        return await Fetch.get<{ id: string; name: string }[]>(
            `${process.env.REACT_APP_API_META_FACADE_URL}accounts/subscribed`,
        )
    } catch (err) {
        if (thrownHasStatus(err, 400)) {
            return
        }
        throw err
    }
}

export const getForms = async (accountId?: string) => {
    if (typeof process.env.REACT_APP_API_META_FACADE_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_META_FACADE_URL is not defined')
    }

    if (!accountId) {
        throw new Error('Missing accountId.')
    }

    return Fetch.get<{ id: string; name: string }[]>(
        `${process.env.REACT_APP_API_META_FACADE_URL}forms?accountId=${accountId}`,
    )
}

export const subscribeAccounts = async (ids: string[]) => {
    if (typeof process.env.REACT_APP_API_META_FACADE_URL === 'undefined') {
        throw new Error('Environment variable REACT_APP_API_META_FACADE_URL is not defined')
    }

    return Fetch.post<{
        failed: { id: string; name: string }[]
        subscribed: { id: string; name: string }[]
    }>(`${process.env.REACT_APP_API_META_FACADE_URL}accounts`, { ids })
}
