import { AuthenticatedFetch, AuthStorage } from '@hiddengemgroup/utils-frontend-fetch'
import router from '../router'
export { initializeGoogleAuth, getTranslation, messageParticipants } from './Api'
export { updatePassword } from './User'
export { upload, deleteUpload, getSigned } from './Upload'
export {
    signIn,
    signOut,
    createAccount,
    refreshToken,
    resetPassword,
    updateResatPassword,
    authenticateGoogle,
    reclaim,
} from './Auth'
export { getServices, updateService, deleteService } from './Service'
export {
    getEvents,
    getEvent,
    updateEvent,
    deleteEvent,
    addExcludedDate,
    manifestVirtualEvent,
    getEventsInService,
} from './Event'
export {
    getBookings,
    cancelBooking,
    refundBooking,
    createBooking,
    updateBooking,
    getBooking,
    moveBooking,
    createManualBookingForSession,
    deleteBooking,
} from './Booking'
export {
    getCompanyProfile,
    getMyProfile,
    updateCompanyProfile,
    updateMyProfile,
    getCompanyUsers,
    getUserinfo,
    firstLogin,
    getClaimableCompanies,
} from './Profiles'
export {
    getVouchers,
    getSoldVouchers,
    updateVoucher,
    deleteVoucher,
    refundVoucher,
} from './Voucher'
export {
    getTemplates,
    getSettings,
    updateSettings,
    getConsumerTemplates,
    getRemindersBySubject,
    updateReminder,
    removeReminders,
    getAllRemindersBySubject,
} from './Notifications'
export { getMetrics, getFunnelMetrics, getMetricsForExperience } from './Analytics'
export { getTerms, createFromTemplate, updateTerms } from './Terms'
export { getOnBoarding, updateOnBoardingStep } from './OnBoarding'
export {
    getDiscounts,
    getDiscount,
    deleteDiscount,
    updateDiscount,
    getRedemptions,
} from './Discount'
export { getPlatformTerms, getPlatformData } from './Platform'
export { getCalendarFeed, updateCalendarFeed, deleteCalendarFeed } from './CalendarFeeds'
export { domainAvailable, createDomain, deleteDomain, checkDomain } from './Domains'
export { getUpcoming } from './Upcoming'
export { updatePayment, getPayment } from './Payment'
export { getInvitationData } from './Invitation'
export { generateExperience } from './Generation'
export { updateProvider, getProviders, getPermissions } from './Marketing'
export {
    getSession,
    getSessions,
    deleteSession,
    updateSession,
    getSessionForService,
    getExternalBookingsByUserId,
} from './Session'
export {
    getLeads,
    updateNote,
    deleteNote,
    getLead,
    getRelated,
    deleteLead,
    updateLead,
    closeLead,
} from './Lead'
export { getCustomers, getCustomer, deleteCustomer, updateCustomer } from './Customer'
export { getRecordsForSubject, deleteRecord, updateRecord, createRecord } from './Record'
export {
    getOffer,
    getOffers,
    deleteOffer,
    updateOffer,
    createOffer,
    resendOffer,
    refundOffer,
    getOffersByCustomerId,
} from './Offer'
export { getAllActivities, getActivitiesForSubject } from './Activity'
export { postMessage as postTelemetry } from './Telemetry'
export {
    getReviewsBySubject,
    getRatingBySubject,
    getRatings,
    getReview,
    deleteReview,
    updateReview,
} from './Review'
export { getFunnel, getFunnels, updateFunnel, deleteFunnel } from './Funnel'
export {
    initLogin,
    getAccounts,
    subscribeAccounts,
    validConnection,
    getForms,
    getSubscribedAccounts,
} from './Meta'
export {
    initOutlookLogin,
    getAvailableCalendars,
    subscribeCalendars,
    validOutlookConnection,
} from './Outlook'
export { getCampaign, getCampaigns, deleteCampaign, updateCampaign } from './Campaign'

const accountUrl = process.env.REACT_APP_API_ACCOUNTS_URL

if (typeof accountUrl === 'undefined') {
    throw new Error('Environment variable REACT_APP_API_ACCOUNTS_URL is not defined')
}

const Fetch = new AuthenticatedFetch(
    `${accountUrl}oauth/token`,
    new AuthStorage('spiritworld-auth'),
    () => router.navigate('/login', { replace: true }),
)

const AccountFetch = new AuthenticatedFetch(
    `${accountUrl}oauth/token`,
    new AuthStorage('spiritworld-auth-claims'),
    () => router.navigate('/login', { replace: true }),
)

export { AccountFetch }
export default Fetch

export interface IShallowSuccessResponse {
    status: 'success'
}

export function thrownHasStatus(e: unknown, status: number) {
    return (e as { response?: { status?: number } } | undefined)?.response?.status === status
}
